import { Text, TextVariant } from '@naf/text';
import { spacing } from '@naf/theme';
import React from 'react';
import styled from 'styled-components';
import { QnaFeedBlockType } from '../../../../../../types/qnaFeedBlockType';
import BlockContent from '../../../block-content/BlockContent';

type HeaderProps = Pick<QnaFeedBlockType, 'title' | 'ingress'>;

const Header = ({ title, ingress }: HeaderProps) => (
  <HeaderContainer>
    <HeaderText variant={TextVariant.Header1}>{title}</HeaderText>
    <IngressContainer>
      <BlockContent value={ingress} />
    </IngressContainer>
  </HeaderContainer>
);

const HeaderText = styled(Text)`
  font-size: 2rem;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: ${spacing.space32};
`;

const IngressContainer = styled.div`
  & p {
    font-size: 1.25rem;
    line-height: 32px;
    margin-top: 0;
    font-style: italic;
  }
`;

// Allows this block to be selected by nesting styled-components
const HeaderContainer = styled.div`
  padding-left: 14px;
`;

export { Header, HeaderContainer };
